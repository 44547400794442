import React from 'react';
import '../node_modules/bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import { Routes, Route, useNavigate } from 'react-router-dom';

import { MainPage } from './pages/Main.page';
import { Users } from './pages/Users.page';
import { Prices } from './pages/Prices.page';
import { Tariffs } from './pages/Tariffs.page';
import { TariffPlanUpsert } from './pages/TariffPlanUpsert.page';
import { Senders } from './pages/Sender.page';
import { SenderUpsert } from './pages/SenderUpsert.page';
import { Payments } from './pages/Payments.page';
import { PaymentUpsert } from './pages/PaymentUpsert.page';
import { TariffPlan } from './pages/TariffPlan.page';
import { TariffPlanAssign } from './pages/TariffPlanAssign.page';

const App = () => {

  let navigate = useNavigate();

  return (
    <div className="App">
      <Routes>
        <Route path='/' element={<MainPage navigate={navigate} />} >
          <Route path='users' element={<Users />} />
          <Route path='prices' element={<Prices />} />
          <Route path='tariffs' element={<Tariffs />} />
          <Route path='add-tariff-plan' element={<TariffPlanUpsert />} />
          <Route path='senders' element={<Senders />} />
          <Route path='create-sender' element={<SenderUpsert />} />
          <Route path='senders/:id' element={<SenderUpsert edit />} />
          <Route path='payment' element={<Payments />} />
          <Route path='add-payment' element={<PaymentUpsert />} />
          <Route path='payment/:id' element={<PaymentUpsert edit />} />
          <Route path='tariff-plan' element={<TariffPlan />} />
          <Route path='assign-tariff-plan' element={<TariffPlanAssign />} />
        </Route>
      </Routes>
    </div>
  );
}

export default App;
