import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

import { MenuLink } from "./menuLink.component";
import { SubMenu } from "./subMenu.component";

export const SideMenu = () => {

    const location = useLocation();
    const [url, setUrl] = useState(null);

    useEffect(() => {
        setUrl(location.pathname);
    }, [location]);

    return (
        <div className='sideMenu'>
            <ul className="sideMenuList">
                <li className={(url === '/users' ? 'activeMenuLink' : '')}>
                    <MenuLink to='/users' icon='users' name='Users' />
                </li>
                <li className={(url === '/prices' ? 'activeMenuLink' : '')}>
                    <MenuLink to='/prices' icon='prices' name='Prices' />
                </li>
                <li className={(url === '/tariffs' ? 'activeMenuLink' : '')}>
                    <MenuLink to='/tariffs' icon='tariffs' name='Tariffs' />
                </li>
                <li className={(url === '/senders' ? 'activeMenuLink' : '')}>
                    <MenuLink to='/senders' icon="senders" name="Senders" />
                </li>
                <li>
                    <SubMenu title="Payment &amp; Tariff Management">
                        <li className={(url === '/payment' ? 'activeMenuLink' : '')}>
                            <MenuLink to='/payment' icon="payment" name="Payment" />
                        </li>
                        <li className={(url === '/tariff-plan' ? 'activeMenuLink' : '')}>
                            <MenuLink to='/tariff-plan' icon="tariffPlan" name="Tariff Plan" />
                        </li>
                    </SubMenu>
                </li>
            </ul>
        </div>
    );
};